var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c(
              "div",
              { staticClass: "form-row justify-content-between" },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-none d-sm-block align-items-center justify-content-left",
                    attrs: { cols: "4", md: "2" },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        label: "title",
                        required: "",
                        searchable: false,
                        options: _vm.perPageOptions,
                        clearable: false,
                      },
                      on: { input: _vm.setPerPageSelected },
                      model: {
                        value: _vm.filters.perPage,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "perPage", $$v)
                        },
                        expression: "filters.perPage",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4", offset: "md-4" } },
                  [
                    _c("b-form-input", {
                      staticClass: "d-inline-block",
                      attrs: {
                        type: "search",
                        placeholder: "Pesquisar...",
                        id: "filterInput",
                      },
                      model: {
                        value: _vm.filter,
                        callback: function ($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.fields,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(status)",
                fn: function (data) {
                  return [
                    data.item.status === 1
                      ? _c(
                          "b-badge",
                          {
                            staticClass: "text-capitalize",
                            attrs: { variant: "light-success" },
                          },
                          [_vm._v(" Ativo ")]
                        )
                      : _vm._e(),
                    data.item.status === 0
                      ? _c(
                          "b-badge",
                          {
                            staticClass: "text-capitalize",
                            attrs: { variant: "light-danger" },
                          },
                          [_vm._v(" Inativo ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(limit)",
                fn: function (data) {
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "text-capitalize",
                        attrs: { variant: "light-secondary" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(data.item.used + "/" + data.item.limit) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(start)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(data.item.start) +
                        " à " +
                        _vm._s(data.item.end) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _vm.$can("Cupom - Excluir", "Comercial")
                          ? _c("feather-icon", {
                              staticClass: "cursor-pointer cursor text-danger",
                              attrs: { icon: "TrashIcon", size: "16" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDelete(data.item.uuid)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.$can("Cupom - Editar", "Comercial")
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "coupons-edit",
                                    params: { uuid: data.item.uuid },
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "EditIcon", size: "16" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }