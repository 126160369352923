<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              :searchable="false"
              v-model="filters.perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
        </div>
      </div>

      <b-table
        :items="dataItems"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        class="position-relative"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status === 1"
            variant="light-success"
            class="text-capitalize"
          >
            Ativo
          </b-badge>
          <b-badge
            v-if="data.item.status === 0"
            variant="light-danger"
            class="text-capitalize"
          >
            Inativo
          </b-badge>
        </template>

        <template #cell(limit)="data">
          <b-badge variant="light-secondary" class="text-capitalize">
            {{ data.item.used + "/" + data.item.limit }}
          </b-badge>
        </template>

        <template #cell(start)="data">
          {{ data.item.start }} à {{ data.item.end }}
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
              v-if="$can('Cupom - Excluir', 'Comercial')"
            />
            <router-link
              v-if="$can('Cupom - Editar', 'Comercial')"
              :to="{
                name: 'coupons-edit',
                params: { uuid: data.item.uuid },
              }"
            >
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import { mapState } from "vuex";

export default {
  components: {
    BFormInput,
    CustomPaginateTable,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    vSelect,
  },
  setup() {
    return { avatarText };
  },
  data() {
    return {
      filters: {
        currentPage: 1,
        perPage: 100,
      },
      submited: false,
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      dataItems: [],
      fields: [
        {
          key: "coupon",
          label: "Código",
        },
        {
          key: "limit",
          label: "Uso",
          thStyle: "width: 100px",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "start",
          label: "Período",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
    };
  },
  computed: {
    ...mapState("Coupon", ["coupons"]),
  },
  mounted() {
    this.getData();
    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  methods: {
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },

    updatePage() {
      this.getData();
    },

    async getData() {
      this.$store.dispatch("Coupon/all", this.filters).then((res) => {
        this.dataItems = res.data;
        this.rowsTable = res.data.length;
        this.totalRows = res.meta.total;
        this.filters.currentPage = res.meta.current_page;
      });
    },

    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },

    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar caso não tenha nenhum cadastro vinculado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Coupon/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
